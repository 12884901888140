.admin{
    .botonesAdmin{
        display: flex;
        button{
            margin: 2em;
            height: 100px;
            color: white;
            background-color: darkslategray;
        }
    }
}