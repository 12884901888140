.home{
    min-height: 100vh;
    background-color: #292A2B;
    color: whitesmoke;
    .title{
        padding-top: 3em;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }
    .description{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto
    }
    .forms{
        margin-top: 3em;
        justify-content: center;
        .ant-form-item-row{
            display: flex;
            padding-bottom: 1em;
            border-bottom: solid 1px rgba(255,255,255,0.5);
            .ant-form-item-label{
                flex: 1;
                text-align:start;
            }
            .ant-form-item-control{
                flex: 1
            }
            label{
                color: whitesmoke;
                text-wrap:wrap;
            }
        }
    }
}
h2{
    color: whitesmoke;
    text-wrap:wrap;
}