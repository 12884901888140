.admon{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .botones{
        display: flex;
        flex-direction: row;
        margin-top: 3em;
        margin-left: 2em;
        &>div{
            margin: 0 1em
        }
    }
    .div-page{
        flex: 1;
        padding: 0 2em;
    }
}