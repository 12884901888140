.login{
    min-height: 100vh;
    background-color: #292A2B;
    color: whitesmoke;
    .title{
        padding-top: 3em;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }
    .description{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto
    }
    .checkbox{
        margin-top: 4em;
        margin-bottom: 4em;
    }
    .form{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    & button{
        &:disabled{
            color: whitesmoke !important
        }
    }
}